<template>
<v-container fluid>
  <date-range @getDates="getSales"></date-range>
  <v-alert v-if="!Object.keys(customers).length && loaded" type="info">
    <p>No data to display.</p>
  </v-alert>
  <v-expansion-panels v-if="Object.keys(customers).length" accordion v-model="panel">
    <v-expansion-panel v-for="(customer, index) of customers" :key="index">
      <v-expansion-panel-header>
        {{index}} ({{customer.total}}) {{customer.productTotals}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="customer.products"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img class="my-2" max-height="50px" max-width="50px"
                :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
              ></v-img>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</template>

<script>
import DateRange from '@/components/DateRange'
import ReportApi from '@/api/admin/report'
import _ from 'lodash'

export default {
  components: {
    DateRange
  },
  data () {
    return {
      panel: null,
      loaded: false,
      imageUrl: process.env.VUE_APP_IMG_URL,
      customers: [],
      headers: [
        {
          text: '',
          width: '5%',
          value: 'index'
        },
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Total',
          align: 'end',
          width: '8%',
          value: 'numItems'
        },
        {
          text: 'Title',
          width: '60%',
          value: 'title'
        },
        {
          text: 'Product',
          value: 'category'
        },
        {
          text: 'SKU',
          value: 'sku'
        }
      ]
    }
  },
  methods: {
    getSales (dates) {
      ReportApi.loadCustomerSales({ dateRange: dates }).then((results) => {
        const sales = results.data
        this.customers = _.groupBy(sales, 'customer')
        for (const customer in this.customers) {
          const productTotals = []
          let totalSalesItems = 0
          const products = _.groupBy(this.customers[customer], 'category')
          for (const product in products) {
            productTotals.push(`${product} ( ${products[product].length})`)
          }
          const ranked = this.customers[customer].map((item, index) => {
            totalSalesItems += item.numItems
            return { ...item, index: index + 1 }
          })
          this.customers[customer] = { total: totalSalesItems, products: ranked, productTotals: productTotals }
        }
        this.loaded = true
      })
    }
  },
  async created () {
  }
}
</script>
